<template>
  <div v-if="profile" class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("profile.details.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("profile.details.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="first-name">{{
              $t("profile.details.field_first_name")
            }}</label>
            <InputText
              v-model="profile.name"
              aria-label="first-name"
              disabled="true"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="surname">{{
              $t("profile.details.field_surname")
            }}</label>
            <InputText
              v-model="profile.surname"
              aria-label="surname"
              disabled="true"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="language">{{
              $t("profile.details.field_language")
            }}</label>
            <input v-model="profile.locale" hidden data-testid="language" />
            <Dropdown
              id="language"
              v-model="profile.locale"
              aria-label="language"
              option-label="language"
              option-value="code"
              :options="availableLocales"
            />
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit" data-testid="button-submit">
            {{ $t("common.save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { GET_PROFILE } from "../../store/modules/auth/auth.getters.type";
import { UPDATE_PROFILE } from "../../store/modules/user/user.actions.type";
import { useLocaleSwitcher } from "../common/useLocaleSwitcher";

export default {
  name: "ChangeProfile",
  components: { Button, Dropdown, InputText },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const profile = computed(() => store.getters[GET_PROFILE]);
    const { availableLocales, onChange } = useLocaleSwitcher();

    const handleSubmit = async () => {
      const profileUpdated = profile.value.changeLocale(profile.value.locale);
      await store.dispatch(UPDATE_PROFILE, profileUpdated);
      await onChange(profile.value.locale);
      await toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("profile.details.toast_success"),
        life: TOAST_LIFE,
      });
    };

    return {
      profile,
      availableLocales,
      handleSubmit,
    };
  },
};
</script>
