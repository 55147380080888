<template>
  <section>
    <change-profile />
  </section>
  <Divider type="dashed" />
  <section>
    <change-password />
  </section>
</template>

<script>
import Divider from "primevue/divider";

import ChangePassword from "@/components/profile/ChangePassword";
import ChangeProfile from "@/components/profile/ChangeProfile";

export default {
  name: "Profile",
  components: {
    ChangeProfile,
    ChangePassword,
    Divider,
  },
};
</script>

<style scoped></style>
