<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("profile.password.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("profile.password.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="current-password">{{
              $t("profile.password.field_current_password")
            }}</label>
            <Password
              v-model="currentPassword"
              aria-label="current-password"
              aria-describedby="current-password-error"
              toggle-mask
              :feedback="false"
              :class="{ 'p-invalid': !!errors['currentPassword'] }"
            />
            <small
              v-if="!!errors['currentPassword']"
              id="current-password-error"
              class="p-error"
              role="alert"
              >{{ $t("profile.password.field_current_password_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="new-password">{{
              $t("profile.password.field_new_password")
            }}</label>
            <Password
              v-model="newPassword"
              aria-label="new-password"
              aria-describedby="new-password-error"
              toggle-mask
              :feedback="true"
              :class="{ 'p-invalid': !!errors['newPassword'] }"
            />
            <small
              v-if="!!errors['newPassword']"
              id="new-password-error"
              class="p-error"
              role="alert"
              >{{ $t("profile.password.field_new_password_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="verify-password">{{
              $t("profile.password.field_verify_password")
            }}</label>
            <Password
              v-model="verifyPassword"
              aria-label="verify-password"
              aria-describedby="verify-password-error"
              toggle-mask
              :feedback="false"
              :class="{ 'p-invalid': !!errors['verifyPassword'] }"
            />
            <small
              v-if="!!errors['verifyPassword']"
              id="verify-password-error"
              class="p-error"
              role="alert"
              >{{ $t("profile.password.field_verify_password_error") }}</small
            >
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit">
            {{ $t("common.save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Button from "primevue/button";
import Password from "primevue/password";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { assertEmpty, assertPassword } from "../../service/assert";
import { USER_CHANGE_PASSWORD } from "../../store/modules/auth/auth.actions.type";

export default {
  name: "ChangePassword",
  components: { Password, Button },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const currentPassword = ref("");
    const newPassword = ref("");
    const verifyPassword = ref("");
    const errors = ref([]);

    async function handleSubmit() {
      if (validateFormInputs()) {
        await store.dispatch(USER_CHANGE_PASSWORD, {
          current: currentPassword.value,
          new: newPassword.value,
        });
        currentPassword.value = newPassword.value = verifyPassword.value = "";
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("profile.password.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateFormInputs() {
      errors.value = [];
      if (assertEmpty(currentPassword.value)) {
        errors.value["currentPassword"] = true;
      }
      if (!assertPassword(newPassword.value)) {
        errors.value["newPassword"] = true;
      }
      if (newPassword.value !== verifyPassword.value) {
        errors.value["verifyPassword"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      currentPassword,
      newPassword,
      verifyPassword,
      errors,
      handleSubmit,
    };
  },
};
</script>
